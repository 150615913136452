import React, { useState, useEffect } from 'react';
import './ContenedorMaterias.css';
import BotonMateria from './BotonMateria';
import imagen_materia from '../Assets/materia.jpg';
import axios from 'axios';
import { getClasesStudent, routeGetGetAllCertificate, mostrarCertificado, descargarCertificado } from '../Pages/constants';
import { useData } from '../Pages/DataContext';

import { useNavigate } from 'react-router-dom'; // Importa el hook useHistory


const ContenedorConstanciasAlumnos = ({ redireccionB }) => {

    const navigate = useNavigate();
    const [certificados, setCertificados] = useState([]);
    const {data} = useData();

    React.useEffect(()=>{
        callForm();
       },[]);

       const callForm = async () => {
        console.log("Viendo Certificado Alumno");
        console.log("Matriculaw: "  + data.matricula);
        try{
            console.log("Entrando" +mostrarCertificado);
            const response = await axios.get(mostrarCertificado, {
                params: {
                    matricula:data.matricula
                }

            });

            console.log("Response data Alumno" + response.data);

            const dataTemp = [];
            response.data.map((item, i)=>{
                dataTemp.push({
                    id_archivo: item.id_archivo,
                    nombre_archivo: item.nombre_archivo,
                    matricula_alumno: item.matricula_alumno,
                    url: descargarCertificado + "?id_archivo=" + item.id_archivo
                });
            });
            setCertificados(dataTemp);
        }catch{
            alert("Error al obtener los certificados");
        }
       }
    const handleRegresarButton = () => {
        navigate("/admin-home");
    };

    function GetPDF (dataUrl){
        axios(`${dataUrl}/pdf`, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(response => {
        //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
        //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleDescargarCertificado = async (idArchivo, nombreArchivo) => {
       /* try {
            const response = await axios.get(`${descargarCertificado}?id_archivo=${idArchivo}`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nombreArchivo}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al descargar el certificado:', error);
        }*/
       const url = `${descargarCertificado}?id_archivo=${idArchivo}`
       GetPDF(url);
    };

    return (
            <div className='container1'>
            <table>
                        <thead>
                            <tr>
                                <th>Curso</th>
                                <th>URL</th>
                            </tr>
                        </thead>
                        <tbody>
                        {certificados.map((contenido, id) => (
                                <tr key={id}>
                                    <td>{contenido.nombre_archivo}</td>
                                    <td>
                                        <div className="zoom-url">
                                            <span onClick={() => handleDescargarCertificado(contenido.id_archivo, contenido.nombre_archivo)}>
                                                Ver Constancia
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
            </div>
    );
};

export default ContenedorConstanciasAlumnos;