import React, { useState, useEffect } from 'react';
import './ContenedorMaterias.css';
import BotonMateria from './BotonMateria';
import imagen_materia from '../Assets/materia.jpg';
import loader_img from '../Assets/spin.svg';
import axios from 'axios';
import { getClasesStudent } from '../Pages/constants';
import { useData } from '../Pages/DataContext';

const ContenedorMateriasAlumno = ({ redireccionB }) => {
  const [materias, setMaterias] = useState([]);
  const { setClaseData } = useData();
  const {data} = useData();
  const [showing, setShowing] = useState(true);
  useEffect(() => {

setTimeout(() => {
  setShowing(false);
  fetchMaterias();

}, 3000)
  }, []);



  const fetchMaterias = async () => {
    try {
      const response = await axios.get(`${getClasesStudent}?matricula_alumno=${data.matricula}`);
      console.log ("!!Contenedor Materias Alumno " + response.data.length);
      console.log ("Response Status " + response.status);
      
      if (response.data.length > 0){
        response.data.forEach(element => {
            console.log("Nombre de Clase "  +element.nombre_clase);
        });

        setMaterias(response.data);
      }else{
        console.log("Entrando al error Materias Alumno");
        alert("Error al obtener las materias  intentalo nuevamente");
        
        
      }
      
    } catch (error) {
      alert("ERR_CMA: Error al obtener las materias intentalo nuevamente");
      console.error('Error al obtener las materias:', error);
    }
  };

  const editarClase = (val) => {
    setClaseData(val);
    console.log("Valores de clase: ", val);
  };

  return (
    <div className="contenedor-materias">
    
      {
        showing ? (
          <img src={loader_img} class = "center"/>
        ):
        (
      materias.map((materia, index) => (
        <BotonMateria
          key={index}
          imagen={imagen_materia}
          nombre={(index+1) + " "+ materia.nombre_clase != null ? materia.nombre_clase : ""}
          descripcion={materia.descripcion}
          salon={materia.matricula_clase}
          profesor={materia.matricula_maestro}
          redireccion={redireccionB}
          onEditarClase={() => editarClase(materia)}
        />
      ))
    
    )}
    </div>
  );
};

export default ContenedorMateriasAlumno;