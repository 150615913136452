import React, { useState } from 'react';
import axios from 'axios';
import './AssignmentPage.css';
import Cabecera from '../Others/Cabecera';
import BotonBarraInferior from '../Others/BotonBarraInferior';
import BarraSuperior from '../Others/BarraSuperior';
import BarraInferior from '../Others/BarraInferior';
import { useNavigate } from 'react-router-dom';
import { postPDFApi } from "./constants";

import PiePagina from '../Others/PiePagina';
import { showFolderContent, selectAllFolders,downloadFolderContent } from "./constants";

const barra_inferior = (
    <BarraInferior contenido={
        <>
            <BotonBarraInferior imagenSrc={require("../Assets/calendario.png")} texto={"Calendario"} redireccion={"m-calendar-page"} />
            <BotonBarraInferior imagenSrc={require("../Assets/tutoriales.png")} texto={"Tutoriales"} redireccion={"m-pagina-tutoriales"} />
            <BotonBarraInferior imagenSrc={require("../Assets/directorio.png")} texto={"Directorio"} redireccion={"m-directory"} />
            <BotonBarraInferior imagenSrc={require("../Assets/entregables.png")} texto={"Entregables"} redireccion={"m-pagina-entregables-pdf"} />
        </>
    } />
);

const menu_materias =<>

</>
const menu_mensajes =<>

</>
const menu_alertas =<>

</>
const menu_actualizaciones =<>

</>

const barra_superior = (
    <BarraSuperior menu_materias={menu_materias} menu_mensajes={menu_mensajes} menu_alertas={menu_alertas} menu_actualizaciones={menu_actualizaciones} redireccion={"monitor-home"} profile_redireccion={"m-profile-page"} />
);

function MonitorAssignmentPDF() {
    const [message, setMessage] = useState("");

    const navigate = useNavigate();
    const navigateToMenu = () => {
        navigate("/m-pagina-entregables")
    }

    // State para manejar la subida de PDF
    const [file, setFile] = useState(null);
    const [matriculaClase, setMatriculaClase] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [carpetas, setCarpetas] = useState([]);
    const [contenidoCarpetas, setContenidoCarpetas] = useState([]);

    // Función para manejar el cambio de archivo
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setErrorMessage('');
    };

    // Función para manejar el cambio de matrícula
    const handleMatriculaClaseChange = (event) => {
        setMatriculaClase(event.target.value);
    };

    const handleMostrarContenido = async (idCarpeta) => {
        const response = await mostrarContenidoCarpetas(idCarpeta);
        if (response.success === false) {
            setMessage(response.message);
        } else {

            setContenidoCarpetas(response); 
            console.log(contenidoCarpetas);// Actualiza el estado con el contenido de la carpeta
        }
    };

    const mostrarContenidoCarpetas = async (idCarpeta) => {
        try {
            const response = await axios.get(showFolderContent, {
                params: {
                    id_carpeta: idCarpeta,
                },
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return { success: false, message: error.response.data.message || 'Error mostrando contenido' };
            }
            return { success: false, message: error.message };
        }
    };

    const selectAllCarpetas = async () => {
        try {
            const response = await axios.get(selectAllFolders);
            setCarpetas(response.data);
            return response.data;
        } catch (error) {
            if (error.response) {
                return { success: false, message: error.response.data.message || 'Error obteniendo carpetas' };
            }
            return { success: false, message: error.message };
        }
    };

    const descargarContenidoCarpetas = async (idArchivo) => {
        try {
            const response = await axios.get(downloadFolderContent, {
                params: {
                    id_archivo: idArchivo,
                },
                responseType: 'blob',
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                return { success: false, message: error.response.data.message || 'Error descargando contenido' };
            }
            return { success: false, message: error.message };
        }
    };

    const handleDescargarContenido = async (idArchivo) => {
        const response = await descargarContenidoCarpetas(idArchivo);
        if (response.success === false) {
            setMessage(response.message);
        } else {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'archivo.pdf'); // Puedes ajustar el nombre del archivo
            document.body.appendChild(link);
            link.click();
        }
    };

    // Función para manejar la subida de PDF
    const handleSubmitPDF = async (event) => {
        event.preventDefault();

        // Verificar que se haya seleccionado un archivo y se haya ingresado la matrícula
        if (file && matriculaClase) {
            const formData = new FormData();
            formData.append('archivo', file);
            formData.append('matricula_clase', matriculaClase);

            try {
                // Realizar la solicitud POST para subir el PDF
                const response = await axios.post(postPDFApi, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // Verificar si la subida fue exitosa
                if (response.data.success) {
                    alert("Archivo subido correctamente");
                } else {
                    setErrorMessage('Error al subir archivo PDF');
                }
            } catch (error) {
                console.error('Error uploading PDF:', error);
                setErrorMessage('Error al subir archivo PDF');
            }
        } else {
            setErrorMessage('Por favor, completa todos los campos y selecciona un archivo PDF.');
        }
    };

    return (
        <div className='contenedor-pagina'>
            <Cabecera contenidosuperior={barra_superior} contenidoInferior={barra_inferior} />
            <div className="folders-container">
                <div className="assignment-holder">
                    <h2>Archivo PDF</h2>
                    <button onClick={selectAllCarpetas}>Mostrar Carpetas</button>

                    <div>
                        <ul>
                            {carpetas.map((carpeta) => (
                                <li key={carpeta.id_carpeta}>{carpeta.nombre_carpeta}
                                    <button onClick={() => handleMostrarContenido(carpeta.id_carpeta)}>
                                        Mostrar contenido
                                    </button>

                                </li>
                            ))}
                        </ul>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Archivo</th>
                                <th>Descargar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contenidoCarpetas.map((contenido, id) => (
                                <tr key={id}>
                                    <td>{contenido.nombre_archivo}</td>
                                    <td><div className="zoom-url"><span onClick={() => handleDescargarContenido(contenido.id_archivo)}>Descargar</span></div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <PiePagina imagenSrc={require('../Assets/piepagina.jpg')} />
            <footer>Grupo Derecho & Progreso &copy; 2024</footer>
        </div>
    );
}

export default MonitorAssignmentPDF;