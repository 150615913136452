import React from "react";
import './ContenidoNoticias.css'

const ContenidoNoticias = () =>{
    return(
        <div className="contenido-noticias">            
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
           <p>Contenido Noticias</p>
        </div>
    )
}

export default ContenidoNoticias