import React, { useState } from 'react';
import axios from 'axios'; //Libreria necesaria npm i axios
import { useNavigate } from 'react-router-dom'; // Importa el hook useHistory
import './CreateClass.css'
import {routeCreateEvaluation} from "./constants";
import {routeGetEvaluations} from "./constants";
import {getClases} from "./constants";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
export const CreateEvaluation = () => {
    const [matricula_clase, setMatricula] = useState('');
    const [evaluacion_diagnostica, SetEvaluacionDiagnostica] = useState('');
    const [evaluacion_final, setEvaluacionFinal] = useState('');
    const [error, setError] = useState('');
    const [id_evaluaciones, selectionOnDropdown] = useState('');
    const navigate = useNavigate();
    const optionsMatricula =[];
    const matriculas = [];
    const defaultOption = 'Selecciona una opcion';
    var id_temp;
    React.useEffect(()=>{
        callMatriculas();
       });

    const callMatriculas = async () => {
        console.log("Esta madre se tiene que abrir");
        try {
            const response = await axios.get(getClases);
            for (let i = 0; i < response.data.length; i++){
                optionsMatricula.push(response.data[i].matricula_clase);
                matriculas.push(response.data[i]);
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error al obtener clases', error);
        }
    }

    function OnSelection(event) {
        console.log(event.value);
        var id_clase = matriculas[optionsMatricula.indexOf(event.value)].id_clase
        console.log(id_clase);
        id_temp = id_clase
        selectionOnDropdown(id_clase);
        CheckEvaluations();
    }

    const CheckEvaluations = async (e) => {
        try {
            var id = id_temp;
            console.log("Id Temp" + id);
            const response = await axios.get(routeGetEvaluations, {
                params:
                {
                    id_evaluaciones: id

                }         });
            console.log(response.data);
            var evaluacion_diagnostica = response.data.evaluacion_diagnostica;
            var evaluacion_final = response.data.evaluacion_final;
            console.log("Evaluacion Diagnostica: " + evaluacion_diagnostica);
            console.log("Evaluacion Final: " + evaluacion_final);
            SetEvaluacionDiagnostica(evaluacion_diagnostica);
            setEvaluacionFinal(evaluacion_final);
        } catch (error) {
            console.error('Error al crear la evaluacion', error);
        }

    };


    const handleSubmitEvaluation = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(routeCreateEvaluation, {

                id_evaluaciones,
                evaluacion_diagnostica,
                evaluacion_final            });
            console.log(response.data);
            alert("Evaluacion creada con exito");
        } catch (error) {
            console.error('Error al crear la evaluacion', error);
            setError('Error al crear evaluacion, por favor intentelo de nuevo');
        }
    };
    const handleRegresarButton = () => {
        navigate("/admin-home");
    };
    return (
        
        <div className="full-page">
            <div className='container1'>
                <form onSubmit={handleSubmitEvaluation}>
                    <div className="title"><h1>Crear Evaluacion</h1></div>
                    <div className='class-name'>
                        <label>Matricula de clase</label>
                        <Dropdown options={optionsMatricula} onChange={OnSelection} value={defaultOption} placeholder="Select an option" />

                    </div>
                    <div className='class-description'>
                        <label>Evuluacion Diagnostica</label>
                        <div><input type="text" value={evaluacion_diagnostica} onChange={(e) => SetEvaluacionDiagnostica(e.target.value)}></input></div>
                    </div>
                    <div className='teacher-id'>
                        <label>Evaluacion Final</label>
                        <div><input type="text" value={evaluacion_final} onChange={(e) => setEvaluacionFinal(e.target.value)}></input></div>
                    </div>
                    <div className='submit'>
                        <button type="submit">Crear Evaluaciones</button>
                        {error && <div>{error}</div>}
                    </div>
                    <div className="underline2"></div>
                </form>
                <div className='return-home'>
                    <span onClick={handleRegresarButton}>Regresar Home</span>
                </div>
            </div>
        </div>
    )
};