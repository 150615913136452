import React, { useState } from 'react';
import axios from 'axios'; //Libreria necesaria npm i axios
import { useNavigate } from 'react-router-dom'; // Importa el hook useHistory
import './WatchCertificate.css'
import {subirCertificado} from "./constants";
import {routeGetGetAllCertificate, descargarCertificado, eliminarCertificado} from "./constants";

export const WatchCertificate = () => {
    const navigate = useNavigate();
    const [certificados, setCertificados] = useState([]);

    React.useEffect(()=>{
        callForm();
       },[]);

       const callForm = async () => {
        console.log("Viendo Certificado");
        try{
            const response = await axios.get(routeGetGetAllCertificate);
            const data = [];
            response.data.map((item, i)=>{
                data.push({
                    id_archivo: item.id_archivo,
                    nombre_archivo: item.nombre_archivo,
                    matricula_alumno: item.matricula_alumno,
                    url: descargarCertificado + "?id_archivo=" + item.id_archivo
                });
            });
            setCertificados(data);
        }catch{
            alert("Error al obtener los certificados");
        }
       }
    const handleRegresarButton = () => {
        navigate("/admin-home");
    };

    function GetPDF (dataUrl){
        axios(`${dataUrl}/pdf`, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(response => {
        //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data], 
              {type: 'application/pdf'});
        //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
            window.open(fileURL);
        })
        .catch(error => {
            console.log("No se puede abrir "  +error);
        });
    }

    const handleDescargarCertificado = async (idArchivo, nombreArchivo) => {
       /* try {
            const response = await axios.get(`${descargarCertificado}?id_archivo=${idArchivo}`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${nombreArchivo}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error al descargar el certificado:', error);
        }*/
       const url = `${descargarCertificado}?id_archivo=${idArchivo}`
       GetPDF(url);
    };

    const handleEliminarCertificado = async (id_archivo) => {
        try {
            console.log("Id archivo eliminado: " + id_archivo);
            const response = await axios.delete(eliminarCertificado, {
                params: {
                    id_archivo: id_archivo,
                },
            });
            console.log("Carpeta eliminada " + response.data);
            alert("Carpeta eliminada.");
            callForm();
            return { success: true, message: 'Certificado eliminada exitosamente' };
        } catch (error) {
            if (error.response) {
                console.log("Error eliminada " + error.response.data);
                alert("Eliminacion fallo.");
                return { success: false, message: error.response.data.message || 'Error eliminando Certificado' };
            }
            return { success: false, message: error.message };
        }
    };

    return (
        <div className="full-page">
            <div className='container1'>
            <div className='title'><h1>Constancias</h1></div>
            <table>
                        <thead>
                            <tr>
                                <th>Matricula</th>
                                <th>URL</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {certificados.map((contenido, id) => (
                                <tr key={id}>
                                    <td>{contenido.matricula_alumno}</td>
                                    <td>
                                        <div className="zoom-url">
                                            <span onClick={() => handleDescargarCertificado(contenido.id_archivo, contenido.nombre_archivo)}>
                                                Ver Constancia
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                    <button onClick={() => handleEliminarCertificado(contenido.id_archivo)}>
                                        Eliminar carpeta
                                        
                                    </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                <div className='return-home'>
                    <span onClick={handleRegresarButton}>Regresar Home</span>
                </div>
            </div>
        </div>
    );
}