import React from "react";
import './ContenidoTutoriales.css'
import anuncios_en_la_plataforma from "../Assets/videos/Anuncios en la  plataforma profesor.mp4"
import calificar_tareas_profesores from "../Assets/videos/Calificar tareas profesor.mp4"
import convertir_excel from "../Assets/videos/Convertir excel en pdf participantes.mp4"
import datos_de_la_clase from "../Assets/videos/Datos de la clase.mp4"
import descargar_constancias_participantes from "../Assets/videos/Descargar constancia participantes.mp4"
import subir_ejercicios_participantes from "../Assets/videos/Subir ejercicios participantes.mp4"
import subir_material_profesor from "../Assets/videos/Subir material profesor.mp4"
import ver_evaluacion_diagnostica_y_final from "../Assets/videos/Ver evaluación diagnóstica y final participantes.mp4"
import visualizar_descargar_material from "../Assets/videos/Visualizar y descargar material participantes.mp4"

export const ContenidoTurotiales = () =>{
    return(
        <div className="contenido-tutoriales"> 
        <div>
            <h1>
                Anuncios en la plataforma
                
                </h1> 
           <video src={anuncios_en_la_plataforma} width="600" height="300" controls="controls" autoPlay={false} />
            </div>    

            <div>
            <h1>
                Calificar Tareas Profesores
                
                </h1> 
           <video src={calificar_tareas_profesores} width="600" height="300" controls="controls"autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Convertir excel en pdf participantes
                
                </h1> 
           <video src={convertir_excel} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Datos de la clase
                
                </h1> 
           <video src={datos_de_la_clase} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Descargar Constancias participantes
                
                </h1> 
           <video src={descargar_constancias_participantes} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Subir ejercicios participantes
                
                </h1> 
           <video src={subir_ejercicios_participantes} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Subir material profesor
                
                </h1> 
           <video src={subir_material_profesor} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Ver evaluación diagnóstica y final participantes
                
                </h1> 
           <video src={ver_evaluacion_diagnostica_y_final} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
            <div>
            <h1>
            Visualizar y descargar material participantes
                
                </h1> 
           <video src={visualizar_descargar_material} width="600" height="300" controls="controls" autoPlay={false}  />
            </div>        
        </div>
    )
}