import React from "react";
import './ContenidoDirectorio.css'

export const ContenidoDirectorios = () =>{
    return(
        <div className="contenido-directorio">            
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
           <p>Contenido Directorios</p>
        </div>
    )
}