import React from "react";
import './ContenidoBancoRecursos.css'

export const ContenidoBancoRecursos = () =>{
    return(
        <div className="contenido-banco-recursos">            
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
           <p>Contenido Banco recursos</p>
        </div>
    )
}